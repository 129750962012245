
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ReportingFullEmpty from '@/components/pages/reporting/ReportingFullEmpty.vue'
import { tooltipsHelpdesk } from '@/definitions/reporting/helpdesk/data'

export default defineComponent({
  components: {
    TmButton,
    ReportingFullEmpty,
  },
  setup() {
    return {
      tooltips: tooltipsHelpdesk,
    }
  },
})
