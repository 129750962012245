import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_horizontal_bar_chart = _resolveComponent("horizontal-bar-chart")!
  const _component_report_graph = _resolveComponent("report-graph")!

  return (_openBlock(), _createBlock(_component_report_graph, {
    title: "Top tags overview",
    tooltip: "Most frequently used tags for tickets created during the selected period.",
    icon: "tmi-chart"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_horizontal_bar_chart, { "chart-data": _ctx.reportingTagsData }, null, 8, ["chart-data"])
    ]),
    _: 1
  }))
}