
import { defineComponent } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'

export default defineComponent({
  components: {
    TmTooltip,
    TopFilter,
    TopFilterButton,
  },
  props: {
    expand: {
      type: Boolean,
    },
  },
  emits: ['update:expand'],
})
