
import { defineComponent, ref, computed } from 'vue'
import { getTableData } from '@/services/tableService'
import TmButton from '@/components/shared/TmButton.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import ReportEmpty from '@/components/shared/templates/reports/ReportEmpty.vue'
import ReportingHelpdeskPriorityFilter from '@/components/pages/reporting/helpdesk/ReportingHelpdeskPriorityFilter.vue'
import ReportingHelpdeskPriorityTable from '@/components/pages/reporting/helpdesk/ReportingHelpdeskPriorityTable.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ReportingTicketRatings } from '@/definitions/reporting/helpdesk/types'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    TmButton,
    WhiteBlock,
    ReportEmpty,
    ReportingHelpdeskPriorityFilter,
    ReportingHelpdeskPriorityTable,
  },
  setup() {
    const { isEmptyHalfMode } = useModes()
    const search = ref('')
    const expand = ref(false)
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Rating', value: 'rating-slot', width: '70px' },
      { text: 'Comment', value: 'comment-slot', width: '40%', expandPossible: true },
      { text: 'Requester', value: 'requester-slot' },
      { text: 'Ticket ID', value: 'id-slot' },
      { text: 'Date received', value: 'date-received-slot', format: (val: string) => formatDate(val), width: '12%' },
    ])
    const tableItems = ref<ReportingTicketRatings[]>(getTableData('ticketRatings'))
    const filteredTableItems = computed<ReportingTicketRatings[]>(() => {
      return tableItems.value.filter((item) => item.comment?.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyHalfMode,
      search,
      expand,
      tableHeaders,
      filteredTableItems,
    }
  },
})
