
import { defineComponent } from 'vue'
import ReportGraph from '@/components/shared/templates/reports/ReportGraph.vue'
import HorizontalBarChart from '@/components/shared/charts/HorizontalBarChart.vue'
import { reportingTagsData } from '@/definitions/shared/charts/reporting/data'

export default defineComponent({
  components: {
    HorizontalBarChart,
    ReportGraph,
  },
  setup() {
    return {
      reportingTagsData,
    }
  },
})
