import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "font-weight-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, { class: "reporting-helpdesk-priority-table" }, {
    "body-cell-rating-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_icon, {
        name: row.satisfied ? 'thumb_up' : 'thumb_down',
        class: _normalizeClass(row.satisfied ? 'success--text' : 'red--text'),
        size: "xLarge"
      }, null, 8, ["name", "class"])
    ]),
    "body-cell-comment-slot": _withCtx(({ row }) => [
      _createElementVNode("div", {
        class: _normalizeClass({
          'light--text': !row.comment
        })
      }, _toDisplayString(row.comment || 'No comment left'), 3)
    ]),
    "body-cell-requester-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_button, {
        "text-link": "",
        class: "blue-on-hover",
        to: { name: 'base.contacts.contactDetails', params: { id: '1' } }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_person, {
            name: row.requester.fullName,
            "avatar-url": row.requester.avatar,
            "avatar-color": row.requester.avatarColor,
            "avatar-size": "small"
          }, null, 8, ["name", "avatar-url", "avatar-color"])
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-id-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_button, {
        "text-link": "",
        to: { name: 'base.tickets.details', params: { id: row.idNumber } },
        class: "blue-on-hover"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(row.idNumber), 1),
          _createVNode(_component_tm_icon, {
            right: "",
            name: "open_in_new",
            size: "small",
            class: "light--text"
          })
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    _: 1
  }))
}