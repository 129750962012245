
import { defineComponent, computed } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ReportCards from '@/components/shared/templates/reports/ReportCards.vue'
import ReportingNavigation from '@/components/pages/reporting/ReportingNavigation.vue'
import ReportingTimeline from '@/components/pages/reporting/ReportingTimeline.vue'
import ReportingHelpdeskRatings from '@/components/pages/reporting/helpdesk/ReportingHelpdeskRatings.vue'
import ReportingHelpdeskEmpty from '@/components/pages/reporting/helpdesk/ReportingHelpdeskEmpty.vue'
import ReportingHelpdeskPriority from '@/components/pages/reporting/helpdesk/ReportingHelpdeskPriority.vue'
import ReportingHelpdeskTags from '@/components/pages/reporting/helpdesk/ReportingHelpdeskTags.vue'
import { reportsFilter } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'
import { useModes } from '@/compositions/modes'
import { reportsHelpdeskStats, reportsHelpdeskStatsHalfEmpty, timelineHelpdeskOptions } from '@/definitions/reporting/helpdesk/data'
import { reportsHelpdeskTimelineChartData, reportsHelpdeskTimelineChartDataHalfEmpty } from '@/definitions/shared/charts/reporting/data'

export default defineComponent({
  components: {
    PageContent,
    ReportCards,
    ReportingNavigation,
    ReportingTimeline,
    ReportingHelpdeskRatings,
    ReportingHelpdeskEmpty,
    ReportingHelpdeskPriority,
    ReportingHelpdeskTags,
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode } = useModes()
    const { filters } = useFilters(reportsFilter)

    const stats = computed(() => isEmptyHalfMode.value ? reportsHelpdeskStatsHalfEmpty : reportsHelpdeskStats)
    const timelineChart = computed(() => isEmptyHalfMode.value ? reportsHelpdeskTimelineChartDataHalfEmpty : reportsHelpdeskTimelineChartData)

    return {
      isEmptyMode,
      filters,
      stats,
      timelineChart,
      timelineHelpdeskOptions,
    }
  },
})
