import type { ReportsTooltips } from '@/definitions/reporting/types'
import type { ReportCardType } from '@/definitions/shared/templates/types'

export const tooltipsHelpdesk: ReportsTooltips[] = [
  {
    label: 'Email helpdesk inbox',
    text: 'Manage all customer emails from your business addresses in one convenient inbox. Streamline communication, respond to customer inquiries, and gather valuable feedback on your support quality.',
  },
  {
    label: 'Filtered views',
    text: 'Easily categorize and organize tickets using various filters, giving you instant access to your custom views for faster, more focused support.',
  },
  {
    label: 'Collaborative features',
    text: 'Work collaboratively on customer inquiries with your team. See who’s active on each ticket in real time, share internal notes, and keep everyone informed to ensure coordinated support.',
  },
]

export const reportsHelpdeskStats: ReportCardType[] = [
  {
    title: 'Tickets solved',
    tooltip: 'Total number tickets marked as Solved during the selected period.',
    counter: '248',
    percent: {
      value: '+12.8%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '210',
  },
  {
    title: 'New tickets',
    tooltip: 'Total number of tickets created during the selected period.',
    counter: '165',
    percent: {
      value: '+9.2%',
      change: 'red',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '58',
  },
  {
    title: 'Avg. resolution time',
    tooltip: 'Average time taken from ticket creation to resolution during the selected period.',
    counter: '1m 2s',
    percent: {
      value: '-14.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '1m 24s',
  },
  {
    title: 'Rated tickets',
    tooltip: 'Total number of tickets rated during the selected period.',
    counter: '114',
    percent: {
      value: '+9.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '108',
  },
  {
    title: 'Satisfaction score',
    tooltip: 'Average satisfaction score for ticket ratings received during the selected period.',
    counter: '96.2%',
    percent: {
      value: '+12.8%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '91.7%',
  },
  {
    title: 'Resolved on first reply',
    tooltip: 'Percentage of tickets solved by one agent reply during the selected period.',
    counter: '91.7%',
    percent: {
      value: '+14.2%',
      change: 'green',
      tooltip: 'Change compared to the previous period of the same length.',
    },
    previously: '77.5%',
  },
]

export const reportsHelpdeskStatsHalfEmpty: ReportCardType[] = reportsHelpdeskStats.map(stat => ({
  ...stat,
  counter: '0',
  previously: '0',
})).map(({ percent, ...stat }) => stat)

export const timelineHelpdeskOptions = [
  'Tickets solved',
  'New tickets',
  'Avg. resolution time',
  'Rated tickets',
  'Satisfaction score',
  'Resolved on first reply',
]
